import axios from "axios";
import {API_URL} from "@/const";

export default {
  methods: {
    // 処分事業者情報取得API   DISPOSER.DETAIL + {this.disposalCompanyId}
    getDisposerInfoApi() {
      return axios
          .get(API_URL.DISPOSER.DETAIL + this.disposalCompanyId)
          .then((res) => {
              this.formEdit.isDisposalProxy = res.data.isDisposalProxy;
              this.formEdit.isCBAContract = res.data.isCbaContract;
              this.formEdit.contractStatusTypeId = res.data.contractStatusInfo.id;
          })
          .catch((err) => {
              console.log(err);
          });
    },
  },
};
